import request from '@http';

// 2022/06/10 王江毅  票据列表
export function getInvoiceList(params) {
    return request({
        method: 'GET',
        url: '/api/invoice/list',
        params
    })
}
// 2022/05/10 王江毅 收据打印
export function invoicePrint(uuid) {
    return request({
        method: 'GET',
        url: `/api/gzf/invoice/${uuid}/print`,
        responseType: 'blob',
    })
}

// 2022/06/10 王江毅 发票操作、开票
export function invoiceAudit(uuid) {
    return request({
        method: 'put',
        url: `/api/invoice/audit/${uuid}`
    })
}

// 2022/06/10 王江毅 申请开票/据 /api/invoice/invoice
export function invoice(params) {
    return request({
        method: 'GET',
        url: '/api/invoice/invoice',
        params
    })
}
