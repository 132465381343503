import {timeFormat, numberFormat} from "@custom";

export function tableColumn(_this) {
    return [
        {
            type: "selection",
            width: 100,
        },
        {
            label: "类型",
            prop: 'typeName',
            width: 100,
        },
        {
            label: "状态",
            prop: "status",
            width: 100,
            render: (h, {row}) => {
                let {status, statusCode} = row;
                let type = "";
                switch (statusCode) {
                    case 1:
                        type = "success";
                        break;
                    case 0:
                        type = "info";
                        break;
                }
                return h(
                    "el-tag",
                    {
                        props: {
                            type: type,
                            size: "small"
                        }
                    },
                    status
                );
            }
        },
        {
            prop: "createTime",
            label: "缴费日期",
            width: 175,
            render: (h, {row}) => {
                let {depositEvent: {depositTimestamp}} = row;
                return timeFormat(new Date(depositTimestamp), "yyyy-MM-dd HH:mm:ss");
            }
        },
        {
            label: "承租人",
            width: 100,
            render: (h, {row}) => {
                let {leasor: {name}} = row;
                return name;
            }
        },
        {
            label: "房源",
            width: 175,
            render: (h, {row}) => {
                let {apartment} = row;
                let name = null;
                if (apartment) {
                    name = apartment.name;
                }
                return name;
            }
        },
        {
            label: "邮箱",
            width: 175,
            render: (h, {row}) => {
                let {leasor: {mailAddress}} = row;
                return mailAddress;
            }
        },
        {
            label: "金额",
            width: 100,
            render: (h, {row}) => {
                let {depositEvent: {rental_amount, expense_amount, depo_amount}} = row;
                let amount = rental_amount + expense_amount + depo_amount;
                return numberFormat(amount / 100);
            }
        },
        {
            label: "支付方式",
            width: 100,
            render: (h, {row}) => {
                let {depositEvent: {paymentMethod}} = row;
                return paymentMethod;
            }
        },
        {
            prop: "userName",
            label: "开票人",
            width: 100,
        },
        {
            label: "开票人账号",
            width: 100,
            render: (h, {row}) => {
                let {currentUser} = row;
                let username = '';
                if (currentUser) {
                    username = currentUser.username;
                }
                return username;
            }
        },
        {
            label: "账单内容",
            render: (h, {row}) => {
                let {depositEvent: {comment}} = row;
                return comment;
            }
        },
    ];
}
